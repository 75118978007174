// app/javascript/controllers/ranking_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "button"]

  connect() {
    const button = this.buttonTarget;
    const rankingValue = parseInt(button.dataset.rankingValue, 10);

    this.rankings = ["None", "Green", "Yellow", "Red"]
    console.log(rankingValue)
    this.currentIndex = rankingValue
  }

  toggle() {
    event.preventDefault()
    this.currentIndex = (this.currentIndex + 1) % this.rankings.length
    console.log("After")
    console.log(this.currentIndex)
    const selectedRanking = this.rankings[this.currentIndex]
    this.inputTarget.value = this.currentIndex
    this.updateButtonColor(selectedRanking)
  }


  updateButtonColor(ranking) {
    // Get the list of classes to remove
    const classesToRemove = Array.from(this.buttonTarget.classList).filter(className =>
      className.startsWith('bg-') || className.startsWith('hover:bg-')
    );

    // Remove the matched classes
    classesToRemove.forEach(className => this.buttonTarget.classList.remove(className));
    // this.buttonTarget.classList.remove("bg-gray-700", "bg-green-800", "bg-yellow-500", "bg-red-800", "hover:bg-grey-900", "hover:bg-green-900", "hover:bg-yellow-600", "hover:bg-red-900")

    switch (ranking) {
      case "None":
        this.buttonTarget.classList.add("bg-gray-400", "text-white", "hover:bg-gray-900")
        this.buttonTarget.innerText = "-"
        break
      case "Green":
        this.buttonTarget.classList.add("bg-green-800", "text-white", "hover:bg-green-900")
        this.buttonTarget.innerText = "G"
        break
      case "Yellow":
        this.buttonTarget.classList.add("bg-yellow-500", "text-white", "hover:bg-yellow-600")
        this.buttonTarget.innerText = "Y"
        break
      case "Red":
        this.buttonTarget.classList.add("bg-red-800", "text-white", "hover:bg-red-900")
        this.buttonTarget.innerText = "R"
        break
    }
  }
}
