// app/javascript/controllers/no_spaces_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.inputTarget.addEventListener("input", this.checkForSpaces)
  }

  disconnect() {
    this.inputTarget.removeEventListener("input", this.checkForSpaces)
  }

  checkForSpaces = () => {
    const inputValue = this.inputTarget.value
    if (inputValue.includes(" ")) {
      this.inputTarget.value = inputValue.replace(/\s/g, "")
    }
  }
}
