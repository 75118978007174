import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["detailedNotes"]

  connect() {
    this.updateNotes = this.updateNotes.bind(this)
    this.updateNotes()
  }

  updateNotes() {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }
    const date = new Date()

    const selectedRequestType = this.element.querySelector('input[name="trade_request[request_type]"]:checked').value
    let notes = ""

    switch (selectedRequestType) {
      case "Email":
        notes = `Reference Request Email sent on ${date.toLocaleDateString(undefined, options)}`
        break
      case "Fax":
        notes = `Reference Request Fax sent on ${date.toLocaleDateString(undefined, options)}`
        break
      case "Phone":
        notes = `Reference Request Phone Call made on ${date.toLocaleDateString(undefined, options)}`
        break
      default:
        notes = ""
    }

    this.detailedNotesTarget.value = notes
  }

}
