import PlacesAutocomplete from 'stimulus-places-autocomplete'

export default class extends PlacesAutocomplete {
  static targets = [ "address", "unit" ]

  connect() {
    console.log('Do what you want here.')
    super.connect()


    // The google.maps.places.Autocomplete instance.
    this.autocomplete
  }

  // You can override the `initAutocomplete` method here.
  initAutocomplete() {
    super.initAutocomplete()
  }

  // You can override the `placeChanged` method here.
  placeChanged() {

    super.placeChanged()
    const place = this.autocomplete.getPlace();
    const streetNumber = place.address_components.find(component => component.types.includes('street_number'));
    const route = place.address_components.find(component => component.types.includes('route'));
    var address_1 = `${streetNumber.long_name} ${route.long_name}`;
    const input = this.addressTarget;
    input.value = address_1;
    this.unitTarget.focus();
  }


}
