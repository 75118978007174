// app/javascript/controllers/button_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "enabledContent", "disabledContent" ]

  connect() {
    console.log('Button controller connected');
  }

  disable(event) {
    this.enabledContentTarget.style.display = 'none';
    this.disabledContentTarget.style.display = 'block';
  }
}





// import { Controller } from "@hotwired/stimulus"

// export default class extends Controller {
//   static targets = [ "enabledContent", "disabledContent" ]

//   disable(event) {
//     event.preventDefault();

//     fetch(event.currentTarget.href)
//       .then(response => {
//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }
//         return response.blob();
//       })
//       .then(blob => {
//         const url = window.URL.createObjectURL(blob);
//         const a = document.createElement('a');
//         a.style.display = 'none';
//         a.href = url;
//         // the filename you want
//         a.download = 'report.pdf';
//         document.body.appendChild(a);
//         a.click();
//         window.URL.revokeObjectURL(url);
//         alert('Your file has downloaded!'); // or you know, something with better UX...
//       })
//       .catch(e => {
//         console.log('There has been a problem with your fetch operation: ' + e.message);
//       });

//     event.currentTarget.disabled = true;
//     this.enabledContentTarget.style.display = 'none';
//     this.disabledContentTarget.style.display = 'block';
//   }
// }
