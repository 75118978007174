import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spinner", "clear"]

  connect() {
    this.hide()
    // document.addEventListener('turbo:before-fetch-request', this.show)
    document.addEventListener('turbo:before-cache', this.hide)
  }

  disconnect() {
    // document.removeEventListener('turbo:before-fetch-request', this.show)
    document.removeEventListener('turbo:before-cache', this.hide)
  }

  show = () => {
    this.spinnerTarget.style.display = 'block';
    if (this.hasClearTarget) {
      this.clearTarget.style.display = 'block';
    }
  }

  hide = () => {
    this.spinnerTarget.style.display = 'none';
  }
}
