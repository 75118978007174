import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "hiddenInput", "form"]

  connect() {
    this.inputs = Array.from(this.inputTargets)
    this.hiddenInput = this.hiddenInputTarget
    this.form = this.formTarget

    // Focus the first input
    if (this.inputs[0] != null) {
      this.inputs[0].focus()
    }

    // Add paste event listener to the first input box
    this.inputs[0].addEventListener('paste', (e) => {
      // Get pasted data
      const pastedData = e.clipboardData.getData('text')
      // Split pasted data into individual characters
      const characters = pastedData.split('')
      // Distribute characters to input boxes
      for (let i = 0; i < characters.length && i < this.inputs.length; i++) {
        this.inputs[i].value = characters[i]
      }
      // Prevent the pasted data from being inserted into the first input box
      e.preventDefault()
      // Update the hidden field
      this.updateHiddenField()

      this.inputs[this.inputs.length - 1].focus()

      this.form.submit()
    })

    this.inputs.forEach((input, index) => {
      input.addEventListener('input', () => {
        if (input.value.length === input.maxLength) {
          if (this.inputs[index + 1] != null) {
            this.inputs[index + 1].focus()
          }
        }
      })

      input.addEventListener('keydown', (e) => {
        if (e.key === 'Backspace' && input.value === '') {
          if (this.inputs[index - 1] != null) {
            this.inputs[index - 1].focus()
          }
        }
      })

      if (index === this.inputs.length - 1) {
        input.addEventListener('input', () => {
          if (input.value.length === input.maxLength) {
            this.form.submit()
          }
        })
      }
    })
  }

  updateHiddenField() {
    const combinedInput = this.inputs.map(input => input.value).join('')
    if (this.hiddenInput) {
      this.hiddenInput.value = combinedInput
    } else {
      console.error('hiddenInput is null')
    }
  }
}
