import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.initAutocomplete();
  }

  initAutocomplete() {
    const event = new Event('google-maps-callback', {
      bubbles: true,
      cancelable: true,
    })
    window.dispatchEvent(event)
  }
}
