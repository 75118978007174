import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import TomSelect from "tom-select"
// Ensure the remove_button plugin is imported if it's not automatically included
// import 'tom-select/dist/esm/plugins/remove_button/plugin'
// import 'tom-select/dist/css/tom-select'

export default class extends Controller {
  static targets = ["selectBox", "clearAll"]

  static values = {
    url: String,
    valueField: {type: String, default: "value"},
    labelField: {type: String, default: "label"},
    submitOnChange: false
  }

  connect() {
    let options = {
      plugins: {
        remove_button: {
          title: 'Remove this item',
        }
      },
      persist: false,
      create: true
    }

    if (this.hasUrlValue) {
      options.valueField = this.valueFieldValue
      options.labelField = this.labelFieldValue
      options.searchField = this.labelFieldValue
      options.load = this.load.bind(this)
    }

    if (this.submitOnChangeValue)
      options.onChange = this.submitOnChange.bind(this)

    // Initialize TomSelect on the selectBox target
    this.select = new TomSelect(this.selectBoxTarget, options)
    this.logItemNames();
  }

  disconnect() {
    this.select.destroy()
  }

  async load(query, callback) {
    const response = await get(`${this.urlValue}?query=${query}`)
    if (response.ok) {
      const json = await response.json()
      callback(json)
    } else {
      callback()
    }
  }

  submitOnChange(value) {
    if (value) {
      this.element.form.requestSubmit()
      this.select.clear(true) // resets silently
    }
  }

  logItemNames() {
    const allOptions = this.select.options;
    const itemNames = Object.values(allOptions).map(option => option.text); // Extracting the label (item name) from each option
  }

  selectAllOptions(event) {
    event.preventDefault(); // Prevent the link from navigating
    const allOptions = this.select.options;
    const allOptionValues = Object.keys(allOptions); // Getting all option values
    this.select.setValue(allOptionValues); // Selecting all options
  }

  clearAllOptions(event) {
    event.preventDefault(); // Prevent the link from navigating
    this.select.clear(true); // Clearing all options
  }

}
