/* eslint no-console:0 */

import "@hotwired/turbo-rails"
require("@rails/activestorage").start()
require("local-time").start()

import "./channels"
import "./controllers"
import "./src/**/*"

// Flowbite
import "flowbite/dist/flowbite.turbo.js";

import { Turbo } from "@hotwired/turbo-rails"

// <turbo-stream action="redirect_advanced" url="<%= projects_path %>"></turbo-stream>
Turbo.StreamActions.redirect_advanced = function () {
  const url = this.getAttribute('url') || '/'
  Turbo.visit(url)
}

Turbo.StreamActions.remove_later = function() {
  setTimeout(() => {
    this.targetElements.forEach((element) => element.remove())
  }, this.getAttribute("after"))

}
