import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbomodal"
export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.focusContainer();
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal()
    }
  }

  hideModal() {
    this.element.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
    this.element.remove()
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  focusContainer() {
    this.containerTarget.focus();
  }

}
