import { Controller } from "@hotwired/stimulus";
import IMask from "imask";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.maskPhoneInput();
  }

  maskPhoneInput() {
    const input = this.inputTarget;
    IMask(input, {mask: "000-000-0000 x0000"} )
  }

}
