import { Controller } from "@hotwired/stimulus"
import { showConfirmModal } from "../src/confirm"

// Connects to data-controller="nested-form"
export default class extends Controller {
  static targets = ["links", "template"]
  static values = { wrapperClass: String }

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
    console.log(this.wrapperClass)
  }

  add_association(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association(event) {
    event.preventDefault()
    console.log("remove_association")
    let wrapper = event.target.closest("." + this.wrapperClass)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }

  remove_association_with_confirmation(event) {
    event.preventDefault()
    console.log("remove_association")

    showConfirmModal(
      "Are you sure?"
    ).then((confirmed) => {
      if (confirmed) {
        this.remove_association(event)
      }
    })
  }
}
